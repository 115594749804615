<template>
  <div id="uploadTemplatePreference">
    <h3 class="text-center text-dark fw-normal fs-5 mb-4">
      Select Your Preference
    </h3>
    <el-row
      type="flex"
      :gutter="30"
      justify="center"
      class="block-types"
    >
      <el-col
        :lg="7"
        :md="10"
        :sm="15"
        :xs="20"
        class="select-your-preference-upload-file"
      >
        <div
          v-loading="loading"
          :element-loading-text="loadingText"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="upload-file-container"
        >
          <el-upload
            class="upload-file-el-upload"
            drag
            name="logo"
            action
            :on-change="uploadFile"
            :show-file-list="false"
            :auto-upload="false"
            accept=".doc, .pdf, .docx, .txt, .png, .jpg, .jpeg, .xls, .xlsx, .xlsm,"
            multiple
          >
            <div class="upload-file">
              <div class="icon py-4">
                <img
                  src="@/assets/img/icons/upload-file.svg"
                  alt="Upload File"
                  height="40"
                />
              </div>
              <h4 class="fw-normal blue pb-2">Upload A File</h4>
              <p class="text-dark fs-9 mb-0">Drag &amp; Drop files here</p>
              <p class="text-muted fs-9 opacity-60 upload-a-file-bottom-text">
                File Supported: PDF,DOC,JPG,PNG,TXT
              </p>
            </div>
          </el-upload>
          <div class="upload-from-other-container" v-if="getAccess('documents','blank_doc','')">
            <el-button
              class="mt-1"
              type="danger"
              @click="chooseBlankDocument"
            >Blank template</el-button>
          </div>
          <span v-if="logoError">{{ logoError }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      :gutter="30"
      justify="center"
      class="name-types"
    >
      <el-col :span="14">
        <draggable
          v-if="this.files.length"
          v-model="files"
        >
          <transition-group
            tag="div"
            name="flip-list"
            id="field-group"
          >
            <div
              class="filename"
              v-for="(file, index) in files"
              :key="JSON.stringify(file)"
            >
              <a class="el-upload-list__item-name">
                <img
                  :src="require('@/assets/img/icons/upload-active.svg')"
                  alt="icon"
                  class="img-active"
                />
                <img
                  v-if="
                    file.raw.type == 'image/jpeg' ||
                    file.raw.type == 'image/jpg'
                  "
                  :src="require('@/assets/img/icons/JPG.svg')"
                  alt="icon"
                  class="img-fluid"
                />
                <img
                  v-else-if="file.raw.type == 'image/png'"
                  :src="require('@/assets/img/icons/PNG.svg')"
                  alt="icon"
                  class="img-fluid"
                />

                <img
                  v-else
                  :src="require('@/assets/img/icons/pdf.svg')"
                  alt="icon"
                  class="img-fluid"
                />
                {{ file.name }}
              </a>
              <div class="direction-buttons">
                <el-button
                  type="text"
                  icon="el-icon-bottom"
                  class="directions"
                  @click="moveDown(index)"
                >
                </el-button>
                <el-button
                  type="text"
                  icon="el-icon-top"
                  class="directions"
                  @click="moveUp(index)"
                >
                </el-button>
                <a @click="deleteItem(index)">
                  <i class="el-icon-circle-close"></i>
                </a>
              </div>
            </div>
          </transition-group>
        </draggable>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      :gutter="30"
      justify="center"
      v-if="files && files.length > 1"
      class="mt-1"
    >
      <el-col :span="14">
        <el-checkbox v-model="isMergeDocument">Process it as a single document?</el-checkbox>
      </el-col>
    </el-row>
    <!-- v-if="!this.$route.query.group" -->
    <el-row
      type="flex"
      :gutter="30"
      justify="center"
      v-if="files && files.length && !this.$route.query.group"
      class="mt-1"
      style="text-align:center;"
    >
      <el-col :span="20">
        <span>Select Groups &nbsp;&nbsp;&nbsp;&nbsp;</span>
        <el-select
          v-model="groupsData"
          placeholder="Select Group"
          multiple
          filterable
          collapse-tags
        >
          <el-option
            v-for="(item, index) of getAllEntityGroupsData.data"
            v-bind:key="index"
            :label="item.name"
            :value="item._id"
          ></el-option>
        </el-select>
        <el-tooltip
          content="Add New Group"
          placement="top"
          effect="light"
        >
          <i
            class="el-icon-circle-plus-outline"
            @click="addGroupPopUP"
          ></i>
        </el-tooltip>
      </el-col>
    </el-row>

    <el-row
      type="flex"
      :gutter="30"
      justify="center"
    >
      <el-col
        :span="6"
        :offset="4"
      >
        <div v-if="files.length > 0">
          <el-button
            type="danger"
            class="text-center upload-btn"
            @click="checkForMergeOrUpload"
          >Next</el-button>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="blanckDocumentModal"
      title="Upload a blank template"
      :width="getIsMobile ? '100%' : '30%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="resetBlankTemplate"
    >
      <div v-loading="blanckDocumentData.loading">
        <span>Enter template name</span>
        <el-input
          placeholder="Enter template name"
          v-model="blanckDocumentData.name"
          class="mb-2 mt-1"
        ></el-input>
        <span>Select number of pages</span>
        <el-select
          placeholder="Select number of pages"
          v-model="blanckDocumentData.pages"
          style="width: 100%"
          class="mt-1"
        >
          <el-option
            v-for="(i, index) in [1, 2, 3]"
            :key="index"
            :label="i"
            :value="i"
          ></el-option>
        </el-select>
        <el-row
          :gutter="30"
          justify="center"
          v-if="!this.$route.query.group"
          class="mt-1"
        >
          <el-col :span="20">
            <span>Select Groups &nbsp;&nbsp;&nbsp;&nbsp;</span>
            <br />
            <el-select
              v-model="groupsData"
              placeholder="Select Group"
              multiple
              filterable
              collapse-tags
            >
              <el-option
                v-for="(item, index) of getAllEntityGroupsData.data"
                v-bind:key="index"
                :label="item.name"
                :value="item._id"
              ></el-option>
            </el-select>
            <el-tooltip
              content="Add New Group"
              placement="top"
              effect="light"
            >
              <i
                class="el-icon-circle-plus-outline"
                @click="addGroupPopUP"
              ></i>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-row
          :gutter="10"
          class="mt-1"
        >
          <el-col
            :offset="12"
            :lg="6"
            :md="10"
            :sm="15"
            :xs="20"
          >
            <el-button @click="resetBlankTemplate">Cancel</el-button>
          </el-col>
          <el-col
            :lg="6"
            :md="10"
            :sm="15"
            :xs="20"
          >
            <el-button
              :disabled="!blanckDocumentData.name.trim()"
              type="danger"
              @click="uploadBlanckDocument"
            >Continue</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <!-- Adding New Group Dialog start -->
    <el-dialog
      title="Add New Group"
      :visible.sync="showAddGroupDialog"
      :width="getIsMobile ? '100%' : '30%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form>
        <div v-loading="loadingSaveGroup">
          <el-form-item
            class="mb-10"
            label="Group Title"
          >
            <el-input
              type="text"
              v-model="groupName"
              placeholder="Enter Title"
            ></el-input>
          </el-form-item>
          <el-form-item class="text-center mt-20">
            <el-button @click="showAddGroupDialog = false">Cancel</el-button>
            <el-button
              type="success"
              @click="saveGroup"
              :disabled="!groupName || !groupName.trim() "
              class="px-20"
            ><i class="el-icon-check"></i> Save Group
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
    <!-- Adding New Group Dialog end -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
import draggable from "vuedraggable";
import appConfig from "@/config/app";
import ComanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
import AccessManagement from "@/mixins/AccessManagement";
export default {
  name: "companyDocuments-UploadDocumentPreference",
  components: {
    draggable
  },
  mixins: [ComanyDocumentsPresignedURLsHelper, DocumentsHelper,AccessManagement],
  data() {
    return {
      loadingText: "Fetching data",
      fieldsFormUpload: {
        title: "",
        type: "CUSTOM",
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: ""
      },
      disabledButton: false,
      dialogVisible: false,
      loading: false,
      logoError: "",
      files: [],
      fileUploadUrls: [],
      fileNames: [],
      isMergeDocument: false,
      fileUploadData: "",
      blanckDocumentModal: false,
      blanckDocumentData: {
        name: "",
        pages: 1,
        loading: false
      },
       blankTemplatesUrl: [
        "/rest/esignature/files/3cc3b9ac-ff3d-4dfa-90a9-96644c719a15.pdf",
        "/rest/esignature/files/dfc42fc5-4696-4e88-81df-8daf1a7a63dd.pdf",
        "/rest/esignature/files/56647615-6ccc-49c6-9991-b72be2706148.pdf"
      ],
      pdfFormFields: {},
      pageCount: 0,
      currentDoc: 0,
      groupsData: [],
      showAddGroupDialog: false,
      groupName: "",
      loadingSaveGroup: false
    };
  },
  computed: {
    ...mapGetters("documents", [
      "getFileUploadURL",
      "getCompanyDocumentAddStatus",
      "getNewCompanyDocument",
      "getCreateConfigureDocumentData"
    ]),
    ...mapGetters("s3FileUpload", ["getDocumentUploadStatus"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getAuthenticationDetails",
      "getActiveWorkspace"
    ]),
    ...mapGetters("fileUpload", [
      "getUploadFileDataStatus",
      "getUploadedPdfData",
      "getCopyDocumentStatus",
      "getCopyDocumentUrl"
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("entityGroups", [
      "getAllEntityGroupsData",
      "getSingleEntityGroupData",
      "getEntityGroupErrors",
      "getError"
    ]),
    ...mapGetters("roles", ["getRoleById", "getSuccess", "getRoleErrors"]),
    uploadHeaders() {
      return {
        Authorization: this.getAuthenticationDetails.access_token
      };
    }
  },
  // beforeDestroy() {
  //   this.clearFormData();
  // },
  methods: {
    async fetchCompanyGroups() {
      try {
        await this.$store.dispatch("entityGroups/fetEntityGroupsData", {
          get_all: true
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchAssignedGroupsByRoleId() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.activeRole) {
        await this.$store.dispatch(
          "roles/fetchRoleById",
          this.getAuthenticatedUser.activeRole.role_id
        );
        if (this.getRoleById && this.getRoleById) {
          this.groupsData = this.getRoleById.allowed_groups;
        } else {
          this.groupsData = [];
        }

        console.log(this.getRoleById);
      }
    },
    async addGroupPopUP() {
      this.showAddGroupDialog = true;

      await this.$store.dispatch("entityGroups/resetErrors");
    },
    async saveGroup() {
      this.loadingSaveGroup = true;
      await this.$store.dispatch("entityGroups/addEntityGroup", {
        name: this.groupName,
        description: "",
        docTemplates: []
      });
      if (
        this.getSingleEntityGroupData &&
        this.getSingleEntityGroupData.success
      ) {
        this.fetchCompanyGroups();
        this.loadingSaveGroup = false;
        this.groupName = "";
        this.showAddGroupDialog = false;
        this.groupsData.push(this.getSingleEntityGroupData.data._id);
      } else if (this.getError && !this.getError.success) {
        this.loadingSaveGroup = false;
        let msg =
          this.getError && this.getError.message
            ? this.getError.message
            : "Something went wrong";
        this.$message.warning(msg);
      }
    },
    async uploadBlanckDocument() {
      this.loading = true;
      this.blanckDocumentData.loading = true;
      this.fieldsFormUpload.title = this.blanckDocumentData.name;
      let selectedFile = this.blankTemplatesData[
        this.blanckDocumentData.pages - 1
      ];

      const copyDocumentData = await this.getCopyDocumentsdata(selectedFile);

      if (copyDocumentData) {
        this.fileUploadUrl = copyDocumentData.path; //
        this.fileUploadUrls = [copyDocumentData.path];
        this.fileNames = [this.blanckDocumentData.name];
        this.disabledButton = false;
        this.isFileIsUploaded = true;
        this.blanckDocumentData.loading = false;
        this.addCompanyDocument();
      } else {
        this.logoError = "Error in uploading file. Please try again..";
      }

      this.blanckDocumentModal = false;
    },
    resetBlankTemplate() {
      this.blanckDocumentModal = false;
      this.blanckDocumentData.name = "";
      this.blanckDocumentData.pages = 1;
    },
    chooseBlankDocument() {
      this.blanckDocumentModal = true;
    },
    moveDown(index) {
      if (index == this.files.length - 1) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index + 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    moveUp(index) {
      if (index == 0) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index - 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    swapSingatureUsers(fromIndex, toIndex, signaturedUsers) {
      let fromUser = signaturedUsers[fromIndex];
      let toUser = signaturedUsers[toIndex];
      signaturedUsers[fromIndex] = toUser;
      signaturedUsers[toIndex] = fromUser;

      return signaturedUsers;
    },
    async deleteItem(index) {
      this.files.splice(index, 1);
    },
    async checkForMergeOrUpload() {
      /** MIXIN **/
      this.loading = true;
      await this.mergeOrUploadFile(); 
      await this.fetchAllDocuments();
      this.loading = false;
    },
    async uploadSingleFile(tempUrl) {
      try {
        this.loading = true;

        this.files.forEach((file, index) => {
          if (index == 0) {
            this.fieldsFormUpload.selectedFile =
              file.raw !== undefined ? file.raw : file;
            this.fieldsFormUpload.selectedFileName =
              file.raw !== undefined ? file.raw.name : file.name;
            this.fieldsFormUpload.title = file.name;
          }
        });

        this.fileUploadUrl = tempUrl[0];
        this.fileUploadUrls = tempUrl;
        this.fileNames = this.files.flatMap(e => e.name);
        this.disabledButton = false;
        this.isFileIsUploaded = true;
        this.addCompanyDocument();
      } catch (err) {
        this.loading = false;
      }
    },
    async mergeAllDocs() {
      try {
        this.loading = true;
        var formData = new FormData();
        this.files.forEach((file, index) => {
          if (index == 0) {
            this.fieldsFormUpload.selectedFile =
              file.raw !== undefined ? file.raw : file;
            this.fieldsFormUpload.selectedFileName =
              file.raw !== undefined ? file.raw.name : file.name;
            this.fieldsFormUpload.title = file.name;
          }
          formData.append(
            "uploadedFiles",
            file.raw !== undefined ? file.raw : file
          );
        });
        let companyId =
          this.getActiveWorkspace && this.getActiveWorkspace.company_id
            ? this.getActiveWorkspace.company_id
            : this.getAuthenticatedUser._id;
        let params = {
          data: formData,
          companyId: companyId
        };
        await this.$store.dispatch("fileUpload/uploadUserDocxFilev4", params);
        this.loading = false;
        if (this.getUploadFileDataStatus) {
          let tempUrl = "";
          if (
            this.getUploadFileDataStatus.url.includes(appConfig.S3_BUCKET_URL)
          ) {
            tempUrl = this.getUploadFileDataStatus.url.replace(
              appConfig.S3_BUCKET_URL,
              ""
            );
          } else {
            tempUrl = this.getUploadFileDataStatus.url;
          }
          this.fileUploadUrl = tempUrl;
          this.fileUploadUrls = [tempUrl];
          this.fileNames = [this.fieldsFormUpload.title];
          this.disabledButton = false;
          this.isFileIsUploaded = true;
          this.addCompanyDocument();
        } else {
          this.logoError = "Error in uploading file. Please try again..";
        }
      } catch (err) {
        this.loading = false;
      }
    },
    async addCompanyDocument() {
      try {
        this.loading = true;
        let params = this.prepareParams();

        if (!params) {
          this.$message.error("Please select a document");
          return;
        }
        await Promise.all([
          this.$store.dispatch("documents/addCompanyDocument", params),
          this.$store.dispatch("documentOperations/removePdfFields", {pdfUrls: this.fileUploadUrls})
        ]);

        if (this.getCompanyDocumentAddStatus) {
          await this.uploadSelectedDocument();
          bus.$emit("document-added", this.fieldsFormUpload.type);
          this.$emit("document-added", this.fieldsFormUpload.type);
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    prepareParams() {
      let params = {};
      params.file_upload_url = this.fileUploadUrl;
      params.file_upload_urls = this.fileUploadUrls;
      params.title = this.fieldsFormUpload.title;
      params.type = this.fieldsFormUpload.type;
      params.file_names = this.fileNames;
      params.groups = this.groupsData
        ? this.groupsData
        : this.$route.query.group;
      return params;
    },

    handleClose() {
      this.dialogVisible = false;
    },
    async beforeLogoUpload(file) {
      console.log(file);
    },

    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000
      };
      await this.$store.dispatch("documents/fetchDocumentUploadUrl", data);
    },
    uploadFile(file) {
      if (
        file.raw.type != "audio/mpeg" &&
        file.raw.type != "video/mp4" &&
        file.raw.type != "image/gif" &&
        file.raw.type != "text/html" &&
        file.raw.type != "text/css" &&
        file.raw.type != "text/javascript" &&
        file.raw.type != "application/json" &&
        file.raw.type != "" &&
        file.raw.type != "application/x-msdownload"
      ) {
        if (file.size < 25000000) {
          this.files.push(file);
        } else {
          this.$message.error("Large file.Maximum upload file size : 25 MB!");
        }
      } else {
        this.$message("File format is not supported");
      }
    },
    async uploadSelectedDocument() {
      this.loading = true;
      this.loadingText = "Uploading Document...";
      if (this.getNewCompanyDocument) {
        let is_template = true;
        await this.$store.dispatch(
          "documents/fetchConfigureDocumentIdByDocumentId",
          this.getNewCompanyDocument._id,
          is_template
        );
        if (this.getCreateConfigureDocumentData) {
          this.$notify.success({
            title: "Success",
            message: "Template created successfully"
          });
          let obj = {};
          obj[
            this.getCreateConfigureDocumentData.configurable_document._id
          ] = this.pdfFormFields;
          await this.$store.commit("documents/setDocumentFormFieldsData", obj, {
            root: true
          });
          this.$router.push({
            name: "edit-configure-template-image",
            params: {
              document_id: this.getNewCompanyDocument._id,
              configurable_document_id: this.getCreateConfigureDocumentData
                .configurable_document._id
            },
            query: this.$route.query
          });
        } else {
          this.$message("Sorry ! Error while creating configurable document");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
      this.loading = false;
    },
    cancel() {
      this.$emit("document-cancelled");
    },
    clearFormData() {
      this.fieldsFormUpload = {
        title: "",
        description: "",
        type: "",
        category: "",
        groups: [],
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: "",
        e_signature_value: "",
        e_signature: ""
      };
    }
  },
  async mounted() {
    this.fetchCompanyGroups();
    this.fetchAssignedGroupsByRoleId();
  },

  beforeDestroy() {
    this.$store.commit("documents/setFileUploadURL", null, { root: true });
    this.$store.commit("documents/setCompanyDocumentAddStatus", null, {
      root: true
    });
    this.$store.commit("documents/setCreateConfigureDocumentData", null, {
      root: true
    });
    this.$store.commit("s3FileUpload/setDocumentUploadStatus", null, {
      root: true
    });
    this.$store.commit("fileUpload/setUploadFileDataStatus", null, {
      root: true
    });
    this.$store.commit("fileUpload/setUploadedPdfData", null, { root: true });
    this.$store.commit("fileUpload/setCopyDocumentStatus", null, {
      root: true
    });
    this.$store.commit("fileUpload/setCopyDocumentUrl", null, { root: true });
    this.clearFormData();
  }
};
</script>

<style lang="scss">
#uploadTemplatePreference {
  padding-top: 5%;
  .el-dialog__body {
    overflow-y: hidden;
  }
  .block-types {
    .upload-file {
      height: 265px;
      .el-upload.el-upload--text {
        height: inherit;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .el-upload-list__item-name {
      margin-top: 1em;
    }
    .d-block {
      .el-button {
        margin-top: 1em;
        background-color: #efefef;
        text-align: center;
        &:hover {
          h4 {
            color: #f754a2;
          }
          border-color: #f754a2;
        }
      }
    }
  }
  .name-types {
    margin-top: 2%;
    .filename {
      height: 56px;
      margin-top: 1%;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dfe3eb;
      box-sizing: border-box;
      border-radius: 2px;
      .el-upload-list__item-name {
        margin-top: 1em;
        .img-active {
          visibility: hidden;
          width: 6px !important;
          margin-right: 10px;
          margin-left: 10px;
          position: relative;
          top: 3px;
        }
        .img-fluid {
          position: relative;
          top: 5px;
          width: 18.43px !important;
        }
      }
      .direction-buttons {
        display: flex;
        visibility: hidden;
        position: relative;
        top: -35%;
        left: 97%;
        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }
      &:hover {
        .img-active {
          visibility: visible;
          width: 6px !important;
          margin-right: 10px;
          margin-left: 10px;
          position: relative;
          top: 3px;
        }
        .direction-buttons {
          display: flex;
          visibility: visible;
          position: relative;
          top: -35%;
          left: 97%;
          .directions {
            position: relative;
            right: 10%;
            top: -10px !important;
          }
        }
      }
    }
  }
  .upload-btn {
    margin-top: 10px;
    position: relative;
    bottom: 0px;
    align-items: center;
  }
}
.select-your-preference-upload-file {
  display: flex;
  justify-content: end;
  .upload-file-container {
    &:hover {
      border: 1px solid #f754a2;
    }
    @media (min-width: 991.98px) {
      min-height: 336px;
    }
    // max-width: 360px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .upload-file-el-upload {
      .block-types {
        .upload-file {
          width: 200px;
          height: 100%;
        }
      }
      .upload-a-file-bottom-text {
        border-bottom: 1px solid #ddd3d3;
        padding: 0 0 5px 0;
        margin: 0px 20px;
      }
      @media (max-width: 991.98px) {
        .el-upload-dragger {
          height: 207px !important;
        }
      }
      .el-upload-dragger {
        border: none;
        width: 360px;
        margin-top: 52px;
      }
    }
    .upload-from-other-container {
      text-align: center;
      .el-upload {
        border: none !important;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border: none;
        }
      }
      .upload-a-file-upload-from-text {
        margin-top: 10px;
      }
      .from-others {
        display: flex;
        justify-content: center;
        .image {
          cursor: pointer;
          margin-left: 10px;
          margin-right: 10px;
          .upload-icon {
            width: 25px;
            height: 25px;
          }
          @media (max-width: 991.98px) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
</style>